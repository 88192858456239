<template>
  <div>
    <div class="schoolname">
      <span>
        {{ school_name }}
      </span>
    </div>
    <div class="xxx" @click="openpaobu">
      <img class="imgs" src="../../assets/orienting.png" alt="" />
      <div class="text-group_6">
        <span class="text_6">智慧跑步</span>
        <span class="text_7">高效有氧运动，耐力锻炼</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openpaobu() {
      this.$router.push("/competition");
    },
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.school_name = this.user.school_name;
  },
};
</script>

<style lang="less" scoped>
.xxx {
  width: 185px;
  height: 278px;
  background: #ffffff;
  box-shadow: 3px 10px 31px 0px rgba(111, 143, 234, 0.1);
  border-radius: 19px 102px 38px 38px;
  margin: 25px;
  .text-group_6 {
    width: 151px;
    height: 53px;
    margin: 6px 0 0 3px;
    text-align: center;
    .text_6 {
      width: 75px;
      height: 26px;
      overflow-wrap: break-word;
      color: rgba(68, 68, 68, 1);
      font-size: 18px;
      font-family: PingFangSC-Regular;
      text-align: left;
      white-space: nowrap;
      line-height: 26px;
      margin-left: 38px;
    }

    .text_7 {
      overflow-wrap: break-word;
      color: rgba(153, 153, 144, 1);
      font-size: 13px;
      font-family: PingFangSC-Regular;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 12px 0px 0px 5px;
    }
  }
  .imgs {
    width: 150px;
    height: 150px;
    margin: 40px 0px 0px 18px;
  }
}

.schoolname {
  margin: 44px 0px 0px 43px;
  width: 364px;
  height: 37px;
  font-size: 28px;
  color: #112bb4;
  font-weight: bold;
}
</style>
